.Toastify__toast {
  cursor: pointer;
}

.Toastify__toast-container {
  position: fixed;
  transform: translate3d(0, 0, 9999px);
  z-index: 9999;
}

.Toastify__toast-container--bottom-left {
  bottom: 1rem;
  left: 1rem;
}

@keyframes Toastify__bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    left: 0;
    margin: 0;
    padding: 0;
    width: 100vw;
  }

  .Toastify__toast-container--bottom-left {
    bottom: 0;
  }
}
