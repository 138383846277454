/* purgecss start ignore */
@import 'tailwindcss/base';
@import 'tailwindcss/components';

@import 'markdown-body.css';
@import 'react-toastify.css';
/* purgecss end ignore */

@import 'tailwindcss/utilities';
@import 'background-cell.css';
@import 'ball.css';
@import 'confetti.css';
@import 'emojis.css';
@import 'modal.css';
@import 'video.css';
@import 'react-markdown-editor-lite/lib/index.css';
@import 'spinner.css';

/* purgecss start ignore */
.ReactModal__Body--open {
  overflow-y: hidden;
}
/* purgecss end ignore */

.debug * {
  box-shadow: 0 0 1px red;
}

@keyframes opacity {
  from {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.appear-after {
  animation: opacity;
  animation-duration: 2s;
}

.bg-cafecito {
  background-color: rgba(65, 137, 230, 0.15);
}

.bg-paypal {
  background-color: #253b80;
}

.bg-telegram {
  background-color: #0088cc;
}

.bg-whatsapp {
  background-color: #25d366;
}

.cursor-poroto {
  cursor: url('/cursor/poroto-md.png') 20 25, pointer;
}

.min-h-650px {
  min-height: 650px;
}

.text-shadow-white {
  text-shadow: 1px 1px 0 white;
}

.toast {
  position: fixed;
  left: 16px;
  transition: bottom 0.5s;
  width: 300px;
}

.visually-hidden {
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  white-space: nowrap; /* added line */
}

.w-1\/10 {
  width: calc(100% / 9);
}

.w-30 {
  width: 30%;
}

@media (max-width: 640px) {
  .cursor-poroto {
    cursor: url('/cursor/poroto-sm.png') 9 12, pointer;
  }

  .toast {
    width: calc(100% - 32px);
  }
}
