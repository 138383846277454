.coronavirus,
.cremona,
.pikachu,
.poroto {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.coronavirus {
  background-image: url('/background-cells/coronavirus.gif');
}

.cremona {
  background-image: url('/background-cells/cremona.png');
}

.pikachu {
  background-image: url('/background-cells/pikachu.png');
}

.poroto {
  background-image: url('/background-cells/poroto.png');
}
