.ball {
  display: block;
  height: 75px;
  position: relative;
  width: 75px;
}

.ball > span {
  background: #fff;
  border-radius: 50%;
  font-size: 25px;
  font-weight: 500;
  height: 60%;
  left: 50%;
  line-height: 175%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
}
