.markdown-body > *:not(:first-child):not(:last-child) {
  @apply my-4;
}

.markdown-body a {
  @apply font-medium;
  @apply text-blue-800;
  @apply underline;
}

.markdown-body a:focus {
  @apply outline-none;
  @apply shadow-outline;
}

.markdown-body blockquote {
  @apply bg-orange-200;
  @apply border-l-2;
  @apply border-orange-600;
  @apply flex;
  @apply items-center;
  @apply p-4;
}

.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  @apply font-medium;
}

.markdown-body table {
  @apply w-full;
}

.markdown-body td,
.markdown-body th {
  @apply border;
  @apply px-4;
  @apply py-2;
  @apply text-left;
}

.markdown-body th {
  @apply bg-gray-100;
}

.markdown-body ol,
.markdown-body ul {
  @apply ml-8;
}

.markdown-body ol {
  list-style-type: decimal;
}

.markdown-body ul {
  list-style-type: disc;
}

@screen md {
  .markdown-body h1 {
    @apply text-2xl;
  }

  .markdown-body h2 {
    @apply text-xl;
  }
}
